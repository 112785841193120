.reservation .banniere{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 30px auto;
    position: relative;
}

.reservation .banniere img, .layer{
    border-radius: 20px;
}

.reservation .banniere .layer{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    color: white;
}
.reservation .banniere .layer p{
    text-transform: capitalize;
    text-align: center;
    font-weight: 700;
    width: 80%;
    font-size: 2rem;
}
.reservation .banniere .layer a{
    text-decoration: none;
    padding: 10px;
    font-size: 20px;
    color: white;
    background-color: none;
    border: 2px solid rgba(255, 255, 255, 0.719);
    transition: 0.2s all ease;
}
.reservation .banniere .layer a:hover{
    border: 2px solid white;
}

/* Formulaire */
.formulaire{
    margin: 50px 0;
}
.formulaire form{
    width: 80%;
    margin: 30px auto;
}
.formulaire form div{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.formulaire input, select{
    height: 30px;
    width: 285px;
}
.formulaire input[type="submit"]{
    font-size: 22px;
    background-color: rgba(0, 0, 0, 0.603);
    color: white;
    font-weight: 500;
    cursor: pointer;
    border: none;
    height: 50px;
    border-radius: 3px;
}