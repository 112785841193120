.publication{
    position: relative;
    background-color: rgba(0, 0, 0, 0.11);
    cursor: pointer;
    padding: 5px;
    transition: 0.2s all ease;
}
.publication:hover{
    transform: scale(1.02);
    box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
                0 32px 64px -48px rgba(0, 0, 0, 0.5);
}

