.details .detail{
    display: flex;
    gap: 1.5rem;
    margin: 50px 20px;
}
.details .detail .detail-img{
    display: flex;
    flex-direction: column;
}
.details .detail .detail-img .detail-image img{
    box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
                0 32px 64px -48px rgba(0, 0, 0, 0.5);
}
.details .detail .detail-images{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    cursor: pointer;
    width: 630px;
    overflow: hidden;
}

.details .detail .detail-text h1{
    padding-bottom: 5px;
    margin-bottom: 20px;
    width: 40px;
    border-bottom: 5px solid gray;
}
.details .detail .detail-text p{
    font-size: 18px;
    display: flex;
    align-content: center;
    margin-bottom: 20px;
    text-align: justify;
}

.details .detail .detail-text .boutton{
    margin-top: 115px;
    font-size: 18px;
    padding: 12px;
    border: 2px solid gray;
    width: max-content;
    cursor: pointer;
    border-radius: 0px 7px 0px 7px;
    transition: 0.2s all ease;
    background-color: rgba(0, 0, 0, 0.479);
}
.details .detail .detail-text .boutton:hover{
    border-radius: 7px 7px 7px 7px;
    background-color: rgba(0, 0, 0, 0.692);
}