.footer .naviguation{
    display: flex;
    justify-content: space-evenly;
    padding: 3rem 0;
    background-color: rgb(1, 1, 21) ;
    color: white;
}
.footer .naviguation div ul{
    list-style: none;
}
.footer .copyright{
    text-align: center;
    padding: 1rem;
    background-color: gray;
}
