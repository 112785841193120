/* Article */
.article{
    margin: 0 auto;
}
.articles{
    margin: 50px 20px;
    align-items: flex-start;
    gap: 1rem;
    display:  flex;
}
.articles .publications{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    flex: 4;
}
.articles .publications .aucun{
    margin: 50px auto;
}
/* Filtre */
.recherche{
    margin: 2rem auto 0 auto;
    padding: 2rem;
    border-radius: 20px;
    width: 77%;
    flex: 1.3;
    box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
                0 32px 64px -48px rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 10px;
    background-color: white;
}

.recherche form{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 1rem;
}

.recherche form div{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.recherche form .barre{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid gray;
    border-radius: 10px;
}
.recherche form .barre input{
    width: 100%;
    border: none;
    border-radius: 10px;
    outline: none;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    padding: 10px;
}

.recherche form div select{
    height: 30px;
    outline: none;
}

/* bouton pour monter  */
.monter{
    display: block;
    text-align: center;
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: black;
    padding: 10px;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.rien{
    display: none;
}

/* responsive phone */
@media screen and (max-width: 1300px){
    .articles{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .articles .recherche label, select, hr{
        display: none;
    }
    .recherche{
        position: inherit;
        width: max-content;
    }
    .publications{
        justify-content: center;
    }
}
@media screen and (max-width: 640px){
    .publications div{
        width: 100%;
    }
    .publications div img{
        width: 100%;
        height: 100%;
    }
}